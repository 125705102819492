@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px; }
  .container_title {
    font-weight: 300;
    font-size: 25px;
    line-height: 1.72;
    color: #eeeeee; }
    .container_title span {
      font-weight: 500;
      color: #fff; }
  .container_box {
    margin: 40px 0; }

li {
  list-style: none; }

.flex-row {
  display: flex; }

.my-50 {
  margin: 50px auto; }

.main_background {
  background: #23262f;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  height: 110vh;
  z-index: -10;
  transition: 0.3s;
  left: -150%; }
  .main_background.show {
    left: 0;
    z-index: 10;
    transition: 0.3s; }

.body-overflow {
  overflow: hidden;
  transition: 0.3; }

.noUi-tooltip {
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  color: #f1c86e; }

.noUi-touch-area {
  cursor: pointer; }

.social_link {
  cursor: pointer;
  transition: .3s; }
  .social_link .icon {
    display: flex;
    align-items: center; }
  .social_link * {
    cursor: pointer; }
  .social_link:hover {
    transition: .3s; }
    .social_link:hover * {
      fill: #F1C86E; }
    .social_link:hover .name {
      color: #F1C86E; }

.overflow-y {
  overflow-x: hidden;
  overflow-y: auto; }

.informationModalWindow {
  display: none;
  background: #23262f !important; }
  .informationModalWindow .fancybox-button * {
    cursor: pointer; }
  .informationModalWindow .fancybox-button svg path {
    color: #EEEEEE; }
  .informationModalWindow .box {
    max-width: 620px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .informationModalWindow .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .informationModalWindow .icon svg {
      width: 100px;
      margin: 0 auto; }
    .informationModalWindow .icon .icon_text {
      color: #EEEEEE;
      font-size: 28px;
      font-weight: 500;
      text-align: center; }
  .informationModalWindow .subtitle {
    font-weight: 300;
    font-size: 18px;
    line-height: 43px;
    text-align: center;
    text-transform: uppercase;
    color: #EEEEEE; }
  .informationModalWindow .text {
    font-size: 18px;
    line-height: 29px;
    color: #ADB7D0; }

.heightMainScreen {
  min-height: calc(100vh - 101px - 86px); }

.super_select {
  color: #f1c86e !important; }

@media screen and (max-width: 1050px) {
  .heightMainScreen {
    min-height: calc(100vh - 89px - 142px); } }

@media screen and (max-width: 992px) {
  .heightMainScreen {
    min-height: calc(100vh - 89px - 192px); } }

.fancybox-close-small * {
  cursor: pointer; }
