.btn {
  font-size: 15px;
  border: none;
  outline: none;
  background: none; }
  .btn-yellow {
    display: inline-block;
    padding: 15px 30px;
    background: #f1c86e;
    box-shadow: 0px 11px 10px rgba(113, 140, 130, 0.1);
    transition: 0.3s;
    color: #23262f; }
    .btn-yellow:hover {
      transition: 0.3s;
      background-color: #c5a96b;
      color: #23262f; }
  .btn-smal-yellow {
    color: #ddb968; }
  .btn-next {
    display: flex;
    align-items: center;
    color: #EEEEEE;
    cursor: pointer; }
    .btn-next .text {
      color: #EEEEEE; }
    .btn-next * {
      cursor: pointer; }
    .btn-next svg {
      cursor: pointer;
      transition: .3s;
      margin-left: 7px;
      width: 31px;
      transform: translate(0, 0); }
    .btn-next:hover svg {
      transition: .3s;
      transform: translate(10px, 0); }
  .btn-start {
    transition: .3s;
    cursor: pointer; }
    .btn-start * {
      cursor: pointer; }
    .btn-start:hover {
      background: rgba(221, 185, 104, 0.9) !important;
      transition: .3s; }

.burger {
  background-color: #f1c86e;
  border: none;
  outline: none;
  padding: 17px 15px; }
  .burger .burger_box span {
    width: 27px;
    height: 4px;
    border-radius: 4px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    will-change: transform;
    display: block;
    background-color: #fff; }
    .burger .burger_box span:nth-child(2) {
      width: 10px;
      margin: 4px 0; }
  .burger.active .burger_box {
    transform: rotateZ(136deg); }
    .burger.active .burger_box span:nth-child(1) {
      width: 27px;
      -webkit-transform: rotateZ(90deg) translate(7.5px, 0px);
      transform: rotateZ(90deg) translate(7.5px, 0px); }
    .burger.active .burger_box span:nth-child(2) {
      width: 27px; }
    .burger.active .burger_box span:nth-child(3) {
      opacity: 0; }
