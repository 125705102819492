.box_info {
  display: grid;
  grid-template-columns: 60fr minmax(500px, 40fr);
  padding-bottom: 20px; }
  .box_info .form_info .form_title {
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    padding: 10px 0; }
  .box_info .form_info .form_footer {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .box_info .form_info .form_footer__help .phone {
      display: flex;
      align-items: center; }
      .box_info .form_info .form_footer__help .phone * {
        cursor: pointer; }
      .box_info .form_info .form_footer__help .phone .icon {
        margin-right: 7px; }
      .box_info .form_info .form_footer__help .phone .value {
        color: #FFFFFF;
        border: 0;
        line-height: 1; }
    .box_info .form_info .form_footer__help .call button {
      font-size: 15px;
      line-height: 17px;
      color: #F1C86E;
      border: none;
      background: none;
      position: relative; }
      .box_info .form_info .form_footer__help .call button:before {
        position: absolute;
        height: 1px;
        content: '';
        width: 100%;
        background: #F1C86E;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: .2; }
  .box_info .sections iframe {
    width: 100%;
    height: 100%;
    border: none; }
  .box_info_form {
    padding: 0 50px;
    margin-bottom: 10%; }

@media (max-width: 992px) {
  .box_info {
    grid-template-columns: 1.2fr 1fr;
    grid-gap: 25px; }
    .box_info_form {
      padding: 0 0px; } }

@media (max-width: 840px) {
  .box_info {
    grid-template-columns: 1fr; }
    .box_info iframe {
      min-height: 450px; }
    .box_info .box_info_form {
      max-width: 450px;
      margin: 0 auto; }
  #map {
    min-height: 400px; } }

@media (max-width: 400px) {
  .box_info .form_footer {
    flex-direction: column-reverse;
    align-items: baseline !important; }
  .box_info .form_footer__control {
    margin: 15px 0;
    width: 100%; }
    .box_info .form_footer__control .btn {
      width: 100%; } }
