.admin__panel_control-exit {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer; }
  .admin__panel_control-exit * {
    cursor: pointer; }
  .admin__panel_control-exit .name {
    margin-right: 10px; }

.admin__panel_button {
  height: 30px;
  position: fixed;
  top: 25%;
  z-index: 99999;
  right: 0;
  width: 30px;
  background: #23262F;
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer; }
  .admin__panel_button svg {
    transform: rotate(0);
    transition: .3s; }
    .admin__panel_button svg path {
      transition: .3s;
      fill: #fff; }
    .admin__panel_button svg:hover {
      transform: rotate(65deg);
      transition: .3s; }
      .admin__panel_button svg:hover path {
        transition: .3s;
        fill: #b5b5b5; }
  .admin__panel_button-active svg {
    transform: rotate(65deg);
    transition: .3s; }
    .admin__panel_button-active svg path {
      transition: .3s;
      fill: #b5b5b5; }

.admin__panel_control {
  margin: 0 10px; }
  .admin__panel_control .control {
    display: flex;
    align-items: center; }
    .admin__panel_control .control .exit {
      margin-right: 20px;
      display: flex;
      align-items: center; }
      .admin__panel_control .control .exit:hover * {
        cursor: pointer; }
      .admin__panel_control .control .exit:hover .name {
        transition: .3s;
        color: #b5b5b5; }
      .admin__panel_control .control .exit:hover .icon svg path {
        transition: .3s;
        fill: #b5b5b5; }
      .admin__panel_control .control .exit .name {
        margin-right: 5px; }

.admin__panel_show {
  display: block;
  cursor: pointer; }
  .admin__panel_show .icon {
    width: 15px;
    height: 15px; }

.admin__panel_wrap {
  position: fixed;
  top: -45px;
  width: 100%;
  height: 40px;
  z-index: 99999;
  display: flex;
  align-items: center;
  background: #23262F;
  transition: .3s;
  display: flex;
  justify-content: space-between; }
  .admin__panel_wrap-active {
    transition: .3s;
    top: 0; }
  .admin__panel_wrap .edit {
    display: flex;
    align-items: center;
    margin: 0 10px; }
    .admin__panel_wrap .edit .button-group {
      display: flex;
      align-items: center;
      margin-right: 20px; }
      .admin__panel_wrap .edit .button-group .name {
        margin: 0 10px;
        color: #b5b5b5; }
      .admin__panel_wrap .edit .button-group:hover * {
        cursor: pointer; }
      .admin__panel_wrap .edit .button-group:hover .name {
        transition: .3s;
        color: #b5b5b5; }
      .admin__panel_wrap .edit .button-group:hover .icon svg path {
        transition: .3s;
        fill: #b5b5b5; }
      .admin__panel_wrap .edit .button-group .name {
        margin-right: 5px; }

.edit .button-group .icon, .control .button-group .icon {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center; }
  .edit .button-group .icon svg, .control .button-group .icon svg {
    width: 100%; }

.db_content {
  position: relative;
  transition: all .2s;
  border: 1px solid transparent; }
  .db_content:hover .db_hidden_content {
    visibility: visible;
    opacity: 1; }
  .db_content-active {
    cursor: pointer;
    border: 1px dashed #b1d628; }
    .db_content-active * {
      cursor: pointer; }

.edit_link {
  position: absolute;
  height: 25px !important;
  width: 25px !important;
  color: #fff !important;
  transition: 0s;
  padding: 3px 4px;
  background-color: #0063ea;
  z-index: 10000;
  top: 0;
  left: 0px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center; }
  .edit_link svg {
    display: block !important;
    width: 100% !important;
    margin: 0 !important; }
  .edit_link:hover {
    transition: 0.3s;
    transform: scale(1.05) translateY(-50%); }
    .edit_link:hover * {
      cursor: pointer; }
  .edit_link svg {
    display: block;
    transform: translate(0, 0) !important; }

@media screen and (max-width: 768px) {
  .admin__panel_wrap .edit .edit_content .switch, .admin__panel_wrap .edit .edit_page .switch {
    margin-left: 5px;
    display: flex;
    align-items: center; }
  .admin__panel_wrap .edit .edit_content .name, .admin__panel_wrap .edit .edit_page .name {
    display: none; } }
