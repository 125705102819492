header {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  transition: .2s;
  padding-bottom: 5px; }

.header_bg {
  background-color: #23262f;
  transition: .2s;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.09); }

.header,
.header_2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }

.header_2 {
  display: none; }

.logo {
  width: 50px;
  margin-right: 10px; }
  .logo a {
    width: 100%; }
    .logo a img {
      width: 100%;
      padding-top: 7px;
      box-sizing: border-box;
      display: block;
      padding-bottom: 2px; }
  .logo_desctop {
    display: block;
    cursor: pointer; }
    .logo_desctop * {
      cursor: pointer; }
  .logo_mobil {
    display: none; }

.shop_wrapper {
  display: block;
  width: 100%;
  height: 100%;
  position: relative; }

.navbar-nav {
  padding: 0;
  display: flex;
  align-items: center; }

.navbar_small {
  padding: 0; }

.admin {
  display: flex;
  gap: 30px;
  align-items: baseline; }
  .admin_panel {
    min-width: 275px;
    padding: 50px 40px;
    background: #23262f;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column; }
    .admin_panel_title {
      font-size: 14px;
      line-height: 16px;
      color: #f1c86e;
      text-align: center;
      margin-bottom: 20px; }
    .admin_panel_main {
      grid-gap: 20px;
      display: grid;
      margin: 40px 0; }
    .admin_panel_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .admin_panel_footer .btn {
        width: 100%;
        color: #212529; }
    .admin_panel_item {
      display: flex;
      align-items: center;
      color: #eeeeee; }
      .admin_panel_item .icon {
        margin-right: 10px; }

.nav-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer !important; }
  .nav-item a {
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #adb7d0; }
  .nav-item .icon {
    display: flex;
    align-items: center;
    margin-left: 5px; }
  .nav-item:hover .nav-link {
    color: #f1c86e; }
  .nav-item .active {
    color: #f1c86e;
    cursor: default; }

.nav-link {
  z-index: 2;
  cursor: pointer; }

.dropdown {
  position: relative; }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 1rem;
    padding: 0 0;
    margin: 0 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #23262f;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.25);
    background-clip: padding-box;
    visibility: hidden;
    transform: translateY(5px);
    opacity: 0;
    transition: 0.3s;
    z-index: 1; }
    .dropdown-menu.show {
      transition: 0.3s;
      visibility: visible;
      transform: translateY(0px);
      opacity: 1;
      z-index: 3; }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.6rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: 0.3s; }
    .dropdown-item:first-child {
      padding-top: .7rem; }
    .dropdown-item:last-child {
      padding-bottom: .7rem; }
    .dropdown-item:hover {
      color: #f1c86e;
      transition: 0.3s; }
    .dropdown-item-active {
      color: #f1c86e;
      transition: 0.3s; }

.leng {
  margin-left: auto; }

.shop,
.acounts {
  width: 44px;
  height: 44px;
  background-color: #23262f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer; }
  .shop_wraper,
  .acounts_wraper {
    position: relative; }
  .shop:hover,
  .acounts:hover {
    transition: 0.3s;
    background-color: #ffffff12; }
  .shop a,
  .acounts a {
    cursor: pointer;
    position: relative; }
    .shop a span,
    .acounts a span {
      position: absolute;
      background: #f1c86e;
      border: 1px solid #adb7d0;
      border-radius: 50%;
      min-width: 18px;
      min-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #23262f;
      font-size: 12px;
      right: -40%;
      top: -40%; }
    .shop a img,
    .acounts a img {
      cursor: pointer; }

.shop.nav-link {
  position: relative; }
  .shop.nav-link .shop_count {
    position: absolute;
    background: #f1c86e;
    border: 1px solid #adb7d0;
    border-radius: 50%;
    min-width: 18px;
    min-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #23262f;
    font-size: 12px;
    right: 0%;
    top: 0%; }

.search {
  display: flex;
  align-items: center;
  width: 200px;
  position: relative;
  padding: 0; }
  .search_field {
    border-radius: 21px;
    text-indent: 1rem;
    padding: 10px 0;
    border: none;
    outline: none;
    position: absolute;
    z-index: 2;
    right: 15px;
    width: 10px;
    transition: 0.3s;
    color: #23262f; }
  .search_icon {
    margin-left: auto;
    width: 44px;
    height: 44px;
    background-color: #23262f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    z-index: 4; }
    .search_icon svg {
      cursor: pointer; }
    .search_icon img {
      cursor: pointer; }
    .search_icon:hover {
      transition: 0.3s;
      background-color: #33353e; }
  .search.active {
    transition: 0.3s; }
    .search.active .search_field {
      transition: 0.3s;
      width: calc(100% - 15px);
      opacity: 1; }
    .search.active .search_icon {
      transition: 0.3s;
      background-color: #33353e;
      box-shadow: -3px 0px 15px rgba(0, 0, 0, 0.5); }

.search_mobil {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative; }
  .search_mobil__box {
    width: 100%; }
  .search_mobil input {
    outline: none;
    border: none;
    background: none;
    padding: 17px 0;
    text-indent: 30px;
    color: #fff; }
  .search_mobil .icon {
    position: absolute;
    right: 0;
    height: 100%;
    width: 55px;
    background: #23262f;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center; }

.acounts {
  position: relative; }
  .acounts_form {
    position: absolute;
    position: absolute;
    top: 39px;
    z-index: 10;
    padding: 30px;
    background: #23262f;
    min-width: 320px;
    right: 50%;
    transform: translate(34%, -4px);
    z-index: -10;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s; }
    .acounts_form.active {
      box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
      transform: translate(34%, 0px);
      z-index: 25;
      opacity: 1;
      visibility: visible; }
  .acounts_modal__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    pointer-events: none; }
    .acounts_modal__header svg {
      margin-right: 10px;
      pointer-events: none; }
    .acounts_modal__header div {
      pointer-events: none;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #adb7d0; }
  .acounts_modal__main {
    margin: 20px 0 0; }
  .acounts_modal__footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center; }

main {
  margin-top: 86px; }

@media (max-width: 1290px) {
  header {
    background-color: #23262f;
    transition: .2s;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.09); }
  .slider_hero {
    margin-top: -30px !important; } }

@media (max-width: 1105px) {
  .nav-item {
    margin-right: 15px; } }

@media (max-width: 1050px) {
  main {
    margin-top: 130px; }
  .logo {
    width: 112px; }
    .logo_desctop {
      display: none; }
    .logo_mobil {
      display: block; }
  .navbar,
  .search,
  .leng {
    display: none; }
  .header_2 {
    display: flex; }
  .navbar_mobile {
    overflow: auto;
    position: absolute;
    top: 54px;
    background: #23262f;
    width: 320px;
    z-index: 10;
    height: calc(100vh - 54px - 76px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: -140%;
    transition: 0.3s; }
    .navbar_mobile.active {
      transition: 0.3s;
      left: 0; }
    .navbar_mobile .navbar-nav {
      padding: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0;
      background: #f1c86e;
      height: auto; }
      .navbar_mobile .navbar-nav .nav-item {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin: 0;
        flex-direction: column; }
        .navbar_mobile .navbar-nav .nav-item .header {
          padding: 20px 15px;
          width: 100%; }
          .navbar_mobile .navbar-nav .nav-item .header .icon_box {
            display: flex; }
            .navbar_mobile .navbar-nav .nav-item .header .icon_box .text {
              color: #23262f; }
            .navbar_mobile .navbar-nav .nav-item .header .icon_box .icon {
              transition: 0.3s;
              transform: rotate(-45deg); }
          .navbar_mobile .navbar-nav .nav-item .header a {
            color: #23262f;
            width: 100%; }
        .navbar_mobile .navbar-nav .nav-item .main {
          width: 100%;
          padding: 0 15px;
          background-color: #23262f;
          max-height: 0px;
          transition: 0.3s; }
          .navbar_mobile .navbar-nav .nav-item .main a {
            color: #f1c86e; }
        .navbar_mobile .navbar-nav .nav-item.active .header .icon_box .icon {
          transition: 0.3s;
          transform: rotate(0deg); }
        .navbar_mobile .navbar-nav .nav-item.active .main {
          padding: 20px 15px;
          max-height: 1000px;
          transition: 0.3s; }
        .navbar_mobile .navbar-nav .nav-item .nav-link.active {
          color: #f1c86e; }
    .navbar_mobile .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px 15px;
      background-color: #f1c86e; }
      .navbar_mobile .footer .phone {
        color: #23262f;
        line-height: 1;
        display: flex;
        align-items: center;
        font-weight: bold;
        cursor: pointer; }
        .navbar_mobile .footer .phone svg {
          margin-right: 10px; }
        .navbar_mobile .footer .phone .value {
          color: #23262F; }
      .navbar_mobile .footer .social_box {
        display: flex;
        align-items: center; }
      .navbar_mobile .footer .social_item {
        margin-left: 10px; } }
