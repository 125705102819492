.social_link {
  display: flex;
  align-items: center; }
  .social_link .name {
    margin-left: 15px;
    font-size: 16px;
    line-height: 18px;
    color: #eeeeee; }

footer .box {
  display: grid;
  grid-template-columns: 1fr 130px 130px 110px;
  grid-gap: 20px; }

.footer_page {
  max-width: 100%;
  background: #23262F;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
  padding: 43px 0; }

.navbar_footer {
  display: flex;
  align-items: center; }
  .navbar_footer .header {
    display: none; }
  .navbar_footer__item {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.1;
    color: #eeeeee;
    margin: 0 0 0 40px; }
    .navbar_footer__item:first-child {
      margin: 0; }

@media (max-width: 992px) {
  footer .box {
    grid-template-columns: 1fr 130px; }
  .navbar_footer {
    display: flex;
    align-items: center;
    grid-row-start: 2; } }

@media (max-width: 768px) {
  footer .box {
    align-items: baseline; }
  .navbar_footer {
    display: flex;
    flex-direction: column;
    align-items: baseline; }
    .navbar_footer .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      min-width: 120px; }
      .navbar_footer .header .icon {
        transition: 0.3s;
        transform: rotate(-90deg); }
    .navbar_footer .main {
      display: flex;
      flex-direction: column;
      margin: 0;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s; }
      .navbar_footer .main .navbar_footer__item {
        max-height: 0px;
        visibility: hidden; }
    .navbar_footer.active .header .icon {
      transition: 0.3s;
      transform: rotate(0deg); }
    .navbar_footer.active .main {
      margin: 5px 0;
      transition: 0.3s;
      max-height: 1000px;
      opacity: 1;
      visibility: visible; }
      .navbar_footer.active .main .navbar_footer__item {
        margin: 5px 0;
        max-height: 100px;
        visibility: visible; } }

.modal_form_wrap {
  display: none; }

.modal_form_box {
  background: #23262f !important;
  padding: 20px 20px; }
  .modal_form_box .fancybox-button svg path {
    fill: #ffffff; }
  .modal_form_box .form_title {
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 20px;
    font-weight: 400; }
  .modal_form_box .input {
    min-width: 280px; }
  .modal_form_box .form_footer__control {
    margin-top: 20px; }
    .modal_form_box .form_footer__control .btn {
      width: 100%; }

#map {
  width: 100%;
  height: 100%; }

.markerMap {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative; }
  .markerMapWrap {
    display: none; }
  .markerMapImg {
    width: 48px; }
  .markerMapText {
    position: absolute;
    left: 120%;
    min-width: 160px;
    font-size: 12px;
    line-height: 14px;
    color: #F1C86E; }

.gm-style-mtc button {
  background-color: #23262f !important;
  color: #fff !important; }

.gm-control-active {
  background-color: #23262f !important; }

.gm-svpc {
  background-color: #23262f !important; }

.gmnoprint div {
  background-color: rgba(0, 0, 0, 0) !important; }

.gm-style img {
  max-width: none !important; }
