.input {
  width: 100%;
  position: relative; }
  .input-hint {
    position: absolute !important;
    top: 50%;
    transform: translate(-10px, -15px);
    right: 0;
    color: #fff !important;
    font-size: 12px !important;
    line-height: 14px !important; }
  .input_box {
    position: relative; }
  .input_label {
    position: absolute;
    top: 12px;
    left: 0;
    transition: 0.3s;
    font-size: 16px;
    line-height: 1; }
  .input-field {
    border: none;
    width: 100%;
    padding: 15px 15px;
    margin-bottom: 15px;
    outline: none;
    width: 100%; }
  .input-grey .input-field {
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.25);
    color: #fff;
    background: #23262f; }
    .input-grey .input-field::placeholder {
      font-size: 12px;
      line-height: 14px;
      color: #828282; }
    .input-grey .input-field:-webkit-autofill, .input-grey .input-field:-webkit-autofill:hover, .input-grey .input-field:-webkit-autofill:focus, .input-grey .input-field:-webkit-autofill:active {
      background: #23262f;
      -webkit-box-shadow: 0 0 0 50px #23262f inset, 20px 20px 50px rgba(0, 0, 0, 0.25);
      -webkit-text-fill-color: #ffff; }
  .input-black .input_label {
    color: #242321; }
  .input-black .input-field {
    color: #242321;
    border-bottom: 1px solid #242321; }
    .input-black .input-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #242321; }
    .input-black .input-field:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #242321; }
  .input-white .input_label {
    color: #fff; }
  .input-white .input-field {
    color: #fff;
    border-bottom: 1px solid #fff; }
    .input-white .input-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #fff; }
    .input-white .input-field:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #fff; }

.in-focus .input_label {
  top: -10px;
  transition: 0.3s;
  font-size: 14px; }

.is-error.in-focus .input_label {
  color: #ff4b4b; }

.is-error .input_label {
  color: #ff4b4b; }

.is-error label.error {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #ff4b4b;
  left: 5px; }

label.error {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #ff4b4b;
  left: 5px; }
