img {
  pointer-events: none; }

svg {
  pointer-events: none; }

.slider_hero {
  min-height: 520px;
  margin-top: -87px; }
  .slider_hero__container {
    position: relative; }
  .slider_hero_bg {
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
    right: 0;
    width: 100%; }
    .slider_hero_bg img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .slider_hero__box {
    position: relative; }
    .slider_hero__box .socials_icons {
      position: absolute;
      right: 35px;
      top: 30%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .slider_hero__box .socials_icons .socials_icon {
        margin-bottom: 50px;
        cursor: pointer;
        display: block; }
        .slider_hero__box .socials_icons .socials_icon * {
          cursor: pointer; }
  .slider_hero .slick-list {
    margin: 0 -20px; }
  .slider_hero__item {
    outline: none;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    min-height: 520px;
    position: relative;
    justify-content: center;
    padding: 0 20px; }
    .slider_hero__item .sections {
      width: 100%;
      height: 100%;
      position: relative;
      min-width: 10px;
      content: "";
      display: flex;
      align-items: center;
      justify-content: center; }
      .slider_hero__item .sections .img {
        position: relative;
        display: block;
        height: 100%;
        width: 100%; }
        .slider_hero__item .sections .img img {
          position: absolute;
          top: 0;
          left: -20%;
          z-index: 1111;
          width: 75%; }
      .slider_hero__item .sections .box .title {
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 43px;
        text-transform: uppercase;
        color: #ffffff; }
        .slider_hero__item .sections .box .title span {
          color: #ffffff;
          font-weight: 500; }
      .slider_hero__item .sections .box .descriptions {
        font-size: 18px;
        line-height: 25px;
        color: #eeeeee;
        margin: 30px 0; }
      .slider_hero__item .sections .box .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 410px; }
        .slider_hero__item .sections .box .footer .form_footer {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .slider_hero__item .sections .box .footer .form_footer__help .phone {
            display: flex;
            align-items: center;
            outline: none;
            margin-bottom: 5px; }
            .slider_hero__item .sections .box .footer .form_footer__help .phone * {
              cursor: pointer; }
            .slider_hero__item .sections .box .footer .form_footer__help .phone .icon {
              margin-right: 10px; }
            .slider_hero__item .sections .box .footer .form_footer__help .phone .value {
              color: #ffffff; }
          .slider_hero__item .sections .box .footer .form_footer__help .call {
            outline: none; }
            .slider_hero__item .sections .box .footer .form_footer__help .call button {
              font-size: 15px;
              line-height: 17px;
              color: #f1c86e;
              border: none;
              background: none;
              position: relative;
              outline: none; }
              .slider_hero__item .sections .box .footer .form_footer__help .call button::before {
                outline: none; }
              .slider_hero__item .sections .box .footer .form_footer__help .call button:before {
                position: absolute;
                height: 1px;
                content: "";
                width: 100%;
                background: #f1c86e;
                bottom: -5px;
                left: 50%;
                transform: translate(-50%, 0);
                opacity: 0.2; }
  .slider_hero .slick-slide {
    min-height: 520px; }
  .slider_hero .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0); }
    .slider_hero .slick-dots .slick-active {
      border-radius: 6px;
      width: 18px;
      height: 6px;
      background: #dadada; }
      .slider_hero .slick-dots .slick-active button {
        display: none; }
    .slider_hero .slick-dots li {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: #adb7d0;
      margin: 0 3px;
      cursor: pointer; }
      .slider_hero .slick-dots li button {
        display: none; }

@media (max-width: 1200px) {
  .slider_hero__item .sections .img img {
    left: 0; } }

@media (max-width: 920px) {
  .slider_hero__item {
    display: flex !important;
    flex-direction: column-reverse; }
    .slider_hero__item .sections {
      height: 50%; }
      .slider_hero__item .sections .img img {
        position: relative;
        width: auto;
        height: 100%;
        max-height: 300px;
        height: 300px;
        margin: 0 auto; }
  .slider_hero_bg {
    top: -26%;
    bottom: unset; }
  .slider_hero .slick-dots {
    bottom: -20px; } }

@media (max-width: 768px) {
  .slider_hero_bg {
    top: -20%; }
  .slider_hero__box .socials_icons {
    position: absolute;
    right: 5px;
    top: 10%; }
  .slider_hero .slick-dots {
    padding: 0; } }

.catalog__item {
  background: #eeeeee;
  transition: 0.3s; }
  .catalog__item img {
    width: 100%; }
  .catalog__item-first_animation {
    animation: 0.4s ease-in-out both fade-in; }
  .catalog__item:hover {
    transform: scale(1.05);
    transition: 0.3s; }
    .catalog__item:hover .main .photo img {
      opacity: 0.5;
      transition: 0.3s;
      min-height: 200px; }
    .catalog__item:hover .main .hover {
      opacity: 1;
      transition: 0.3s; }
      .catalog__item:hover .main .hover .btn {
        cursor: pointer; }
  .catalog__item .header {
    padding: 15px 15px 0; }
    .catalog__item .header .info {
      font-size: 14px;
      line-height: 16px; }
      .catalog__item .header .info .status {
        color: #adb7d0; }
      .catalog__item .header .info .price {
        color: #403f3b;
        line-height: 2; }
    .catalog__item .header .marker {
      font-size: 14px;
      line-height: 22px;
      color: #23262f;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px; }
      .catalog__item .header .marker .new {
        background: #adb7d0;
        padding: 0 5px;
        text-align: center;
        color: #23262f; }
      .catalog__item .header .marker .sale {
        background: #f1c86e;
        padding: 0 5px;
        text-align: center;
        color: #23262f; }
  .catalog__item .main {
    padding: 15px 15px 0;
    position: relative; }
    .catalog__item .main .photo {
      cursor: pointer;
      display: block;
      margin: 10px 0; }
      .catalog__item .main .photo img {
        width: 100%;
        cursor: pointer;
        transition: 0.3s;
        mix-blend-mode: darken; }
    .catalog__item .main .name {
      cursor: pointer;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #403f3b;
      display: block;
      margin: 5px 10px 15px; }
    .catalog__item .main .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: 0.3s; }
  .catalog__item .footer {
    display: none;
    background-color: #f1c86e;
    text-align: center;
    padding: 15px 0; }
  .catalog__item_animation {
    width: 100%;
    background-color: #eeeeee1a;
    animation: 1s ease 0s pulse infinite; }

.best__offers_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  .best__offers_header .title {
    font-weight: 300;
    font-size: 25px;
    line-height: 35px;
    color: #eeeeee; }
    .best__offers_header .title span {
      font-weight: 500;
      color: #fff; }

.best__offers_main {
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 40px 0; }

.projects {
  display: grid;
  grid-template-columns: minmax(300px, 0.7fr) 1fr;
  grid-gap: 40px;
  position: relative; }
  .projects_categories {
    overflow: auto;
    direction: rtl;
    padding-left: 10px; }
    .projects_categories .os-scrollbar-track {
      width: 2px;
      right: -2px; }
    .projects_categories .os-scrollbar {
      background-color: rgba(173, 183, 208, 0.2) !important;
      width: 1px;
      padding: 0; }
    .projects_categories .categories {
      display: grid;
      place-items: center;
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
      overflow: auto;
      grid-gap: 30px; }
    .projects_categories .categorie {
      display: grid;
      place-items: center;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      overflow: auto; }
      .projects_categories .categorie__item {
        position: relative;
        background: #23262f;
        max-width: 300px;
        max-height: 200px;
        height: auto;
        overflow: hidden; }
        .projects_categories .categorie__item::after {
          content: "";
          border: 1px solid #adb7d0;
          box-sizing: border-box;
          position: absolute;
          opacity: 0.2;
          top: 50%;
          left: 50%;
          height: 100%;
          width: 100%;
          transform: translate(-50%, -50%);
          transition: 0.3s; }
        .projects_categories .categorie__item img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
          opacity: 0.2;
          filter: grayscale(100%); }
        .projects_categories .categorie__item_text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #adb7d0;
          font-size: 16px;
          line-height: 29px;
          font-weight: 300;
          text-transform: uppercase;
          text-align: center;
          transition: 0.3s; }
          .projects_categories .categorie__item_text span {
            font-weight: 500; }
        .projects_categories .categorie__item:hover:not(.active)::after {
          background: #f1c86e;
          opacity: 1;
          opacity: 0.8;
          transition: 0.3s;
          z-index: 1; }
        .projects_categories .categorie__item:hover:not(.active) .categorie__item_text {
          cursor: pointer;
          transition: 0.3s;
          color: #23262f;
          opacity: 1;
          z-index: 2; }
          .projects_categories .categorie__item:hover:not(.active) .categorie__item_text span {
            cursor: pointer; }
          .projects_categories .categorie__item:hover:not(.active) .categorie__item_text span {
            color: #23262f; }
  .projects_img {
    position: relative; }
    .projects_img_light {
      height: 100%;
      position: absolute;
      bottom: -11px;
      z-index: 2; }
    .projects_img_bg {
      position: absolute;
      bottom: -4px;
      left: -110px; }
    .projects_img .bg_img {
      position: absolute;
      left: -100px;
      bottom: 0;
      width: 120%;
      height: 90%;
      display: flex;
      align-items: flex-end; }
      .projects_img .bg_img img {
        width: 100%; }

.our_projects_wrap {
  max-width: 1400px;
  margin: 0 auto; }

.sliders__our_projects .sliders__items img {
  width: 100%;
  display: inline-block; }

.sliders__our_projects.slick-slider {
  width: 100%;
  background-color: transparent; }
  .sliders__our_projects.slick-slider .slick-track {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center; }
  .sliders__our_projects.slick-slider .slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0px;
    margin: 10px;
    transition: all 0.3s ease;
    height: auto;
    text-align: center;
    transform: scale(1);
    z-index: 5;
    opacity: 0.5;
    letter-spacing: 0.255em;
    text-transform: uppercase;
    color: #eeeeee;
    outline: none; }
    .sliders__our_projects.slick-slider .slick-slide .title {
      color: #fff; }
    .sliders__our_projects.slick-slider .slick-slide.slick-current, .sliders__our_projects.slick-slider .slick-slide.slick-center {
      opacity: 1;
      transform: scale(1.2);
      padding: 30px 0px;
      z-index: 10;
      filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.5)); }

.sliders__our_projects .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0; }
  .sliders__our_projects .slick-dots .slick-active {
    border-radius: 6px;
    width: 18px;
    height: 6px;
    background: #dadada; }
    .sliders__our_projects .slick-dots .slick-active button {
      display: none; }
  .sliders__our_projects .slick-dots li {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: #adb7d0;
    margin: 0 3px;
    cursor: pointer; }
    .sliders__our_projects .slick-dots li button {
      display: none; }

.our__services {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  grid-gap: 10px; }
  .our__services_item {
    display: flex;
    align-items: center; }
    .our__services_item .descriptions {
      color: #adb7d0;
      font-size: 15px;
      line-height: 1.35;
      margin: 0 10px;
      text-transform: uppercase; }
      .our__services_item .descriptions span {
        font-weight: 500; }

.we_cooperate {
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
  gap: 30px;
  pointer-events: none; }

.our_goal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  margin-top: 120px; }
  .our_goal__wraper {
    overflow: hidden; }
  .our_goal .sections_bg {
    position: absolute;
    bottom: 0px;
    left: -110px; }
  .our_goal .sections .form {
    background: #23262f;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.25);
    display: block;
    position: relative;
    margin-left: -55px;
    margin-top: 30px; }
    .our_goal .sections .form .text {
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #f1c86e;
      padding: 54px 90px; }
      .our_goal .sections .form .text:before {
        content: "";
        position: absolute;
        height: 103px;
        width: 2px;
        background: #f1c86e;
        left: 0;
        bottom: 0; }
    .our_goal .sections .form .btn {
      position: absolute;
      right: 110px;
      bottom: 0;
      transform: translate(0, 50%);
      width: fit-content; }
    .our_goal .sections .form_img {
      position: absolute;
      right: -90px;
      width: auto;
      height: 190%;
      top: 50%;
      transform: translate(0, -50%); }
  .our_goal_title {
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: 0.255em;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 300px; }
    .our_goal_title-mod1 {
      text-transform: none; }
    .our_goal_title-mod2 {
      font-weight: 300;
      font-size: 40px;
      line-height: 43px;
      text-transform: uppercase; }
      .our_goal_title-mod2 span {
        font-weight: 500;
        color: #fff;
        letter-spacing: 0.2rem;
        margin-right: 5px; }
  .our_goal_text {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    opacity: 0.5; }
  .our_goal_box {
    width: 60%;
    margin: 0 0 0 auto; }
  .our_goal_year {
    display: flex;
    align-items: center;
    margin: 25px 0; }
    .our_goal_year .year {
      font-weight: normal;
      font-size: 150px;
      line-height: 150px;
      text-transform: uppercase;
      color: #adb7d0; }
    .our_goal_year .text {
      font-weight: 300;
      font-size: 30px;
      line-height: 43px;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      color: #adb7d0; }
  .our_goal_logo {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 156px; }

@media (max-width: 1115px) {
  .our_goal_box {
    width: 68%; }
  .our_goal .sections .form .text {
    padding: 44px 70px 44px 40px; } }

@media (max-width: 992px) {
  .sliders__our_projects {
    width: 100%;
    padding: 0 15px; }
  .our_goal .sections .form_img {
    right: -10px; }
  .our_goal {
    grid-template-columns: 1fr; }
    .our_goal .sections .sections_bg {
      top: -20px;
      bottom: unset;
      left: 20%;
      width: auto;
      height: 100%;
      transform: translate(-50%, 0); }
    .our_goal .sections .form {
      margin-left: 0; }
      .our_goal .sections .form .text {
        padding: 54px 120px 44px 30px; }
        .our_goal .sections .form .text:before {
          display: none;
          content: "";
          position: absolute;
          height: 103px;
          width: 2px;
          background: #f1c86e;
          left: 0;
          bottom: 0; }
      .our_goal .sections .form .btn {
        left: 50%;
        right: auto;
        transform: translate(-50%, 50%); }
    .our_goal_logo {
      top: 0;
      transform: translate(0, 0); }
    .our_goal_box {
      width: 50%; } }

@media (max-width: 768px) {
  .our_goal .sections .form .text {
    padding: 54px 70px 44px 30px; }
  .our_goal .sections .form_img {
    display: none; }
  .slider_hero__item .sections .box .title {
    text-align: center;
    font-size: 25px; }
  .slider_hero__item .sections .box .descriptions {
    text-align: center;
    margin: 20px 0; }
  .slider_hero__item .sections .box .footer {
    margin: 0 auto; }
    .slider_hero__item .sections .box .footer .btn {
      margin: 0 auto; }
  .slider_hero .form_footer__help {
    display: none; }
  .sliders__our_projects .sliders__items .title {
    font-size: 12px; }
  .sliders__our_projects .slick-dots {
    padding: 0; }
  .projects_categories {
    padding-left: 0; } }

@media (max-width: 668px) {
  .our_goal .sections .form .text {
    padding: 44px 20px 44px 30px; }
  .our_goal .sections .form_img {
    display: none; } }

@media (max-width: 520px) {
  .slider_hero .slick-track {
    height: 100% !important; }
  .slider_hero__item .sections .img img {
    height: 270px; } }

@media (max-width: 498px) {
  .our__services {
    grid-gap: 20px; }
  .our_goal {
    width: 100%;
    margin-bottom: 80px; }
    .our_goal .sections {
      position: relative; }
    .our_goal_box {
      width: 100%;
      margin: 0 auto 10px; }
    .our_goal_title {
      max-width: 100%; }
      .our_goal_title-mod2 {
        font-size: 25px; }
    .our_goal_logo {
      display: none; }
    .our_goal .sections .sections_bg {
      width: 100%;
      left: 50%;
      margin-top: 0px;
      display: flex;
      align-items: flex-end; }
      .our_goal .sections .sections_bg img {
        width: 100%;
        height: auto;
        object-fit: cover; }
  .our_goal_year {
    margin: 25px 0 0; } }

@media (max-width: 992px) {
  .categories {
    grid-gap: 15px !important; } }

@media (max-width: 850px) {
  .categories .categorie__item {
    grid-gap: 15px !important;
    width: 100%;
    height: 90px; } }

@media (max-width: 768px) {
  .projects {
    display: grid;
    grid-template-columns: 1fr; }
    .projects_img {
      display: none; }
    .projects_categories .categories {
      grid-template-columns: 1fr; }
      .projects_categories .categories .categorie__item {
        height: 120px; } }

@media (max-width: 560px) {
  .projects_categories .categories {
    grid-template-columns: 1fr; }
    .projects_categories .categories .categorie__item {
      height: 90px; } }

.search__info {
  padding-top: 40px;
  margin-bottom: 20px;
  width: 100%; }
  .search__info_title {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 25px;
    line-height: 35px;
    color: #eeeeee; }
    .search__info_title span {
      color: #adb7d0;
      margin-right: 10px;
      font-weight: 500; }
  .search__info_query {
    display: flex;
    align-items: center; }
    .search__info_query_title {
      font-weight: 500;
      font-size: 22px; }
    .search__info_query_value {
      margin-left: 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 300; }
  .search__info_empty {
    color: #fff;
    font-size: 18px;
    margin: 10px 0; }

@media (max-width: 410px) {
  .best__offers_header {
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 0px; }
    .best__offers_header .btn-next {
      margin-top: 10px; } }
