.breadcrumbs {
  display: flex; }
  .breadcrumbs_item {
    display: flex;
    align-items: center;
    margin: 20px 0; }
    .breadcrumbs_item .text {
      transition: all .2s;
      cursor: pointer !important; }
    .breadcrumbs_item .separaror {
      margin: 0 5px; }
    .breadcrumbs_item.active {
      color: #f1c86e; }
      .breadcrumbs_item.active .text {
        color: #f1c86e; }
      .breadcrumbs_item.active .separaror {
        display: none; }
    .breadcrumbs_item:hover .text {
      color: #f1c86e; }
  .breadcrumbs_wrap {
    border-bottom: 1px solid rgba(173, 183, 208, 0.2);
    border-top: 1px solid rgba(173, 183, 208, 0.2); }
